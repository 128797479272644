import { defineStore} from "pinia";
import en from '../other/lang/en.json';
import de from '../other/lang/de.json';
import emailjs from '@emailjs/browser';
import axios from "axios";
import { ref } from "vue";
// @ts-ignore
import * as bootstrap from 'bootstrap/dist/js/bootstrap.min.js'
import { useTicketsStore } from "./tickets";
import { useAuthStore } from "./authStore";

export const useManageStore = defineStore("manageStore", {
    state: () =>{
        return {
            //users
            baseUserImageUrl: "https://chat.wmaut.de/avatar/",
            baseEmail: "@wzl.rwth-aachen.de",
            showNavbar: false,

            //email
            emailTemplates: {
                assigned: "template_99i7dlg",
                printed: "template_l0z6tfk"
            } as any,

            //ui
            currentDialog: ref({}) as any,
            dialogs: [
                {name: "UploadModal", size: "lg"},
                {name: "EditTicketModal", size: "lg"},
                {name: "SettingsModal", size: "lg"},
                {name: "AssigneeModal", size: "md"},
                {name: "PrinterModal", size: "lg"},
                {name: "PrinterModal", size: "lg"},
                {name: "AssignOptionsModal", size: "md"},
                {name: "removeTicketModal", size: "sm"},
                {name: "UserOverview", size: "lg"},
              ],

            //filters
            filterTypes: ["active", "not assigned", "in progress", "printed", "all"],
            selectedFilter: "active",
            
            showViewport: true,
            viewportLoaded: true,
            //global
            localeLang: "en",
            errorCode: null,

            //misc
            feedback_table_url: "https://bookstack.wmaut.de/books/smart-automation-lab/page/printing-system-feedback-table"
        };
    },
    actions: {

        serverfetchRoutine(){
            useTicketsStore().fetchAllTickets();
            useTicketsStore().fetchAllPropertyTables();
            useAuthStore().fetchAllUsers();
            useAuthStore().fetchAllPrinterUsers();
        },
        /**
         * Gets from the json files the language specific text.
         * @param path Path string to the wanted text.
         * @returns the text as string.
         */
        lang(path: any){
            let result: {[index: string]:any} = {}
            if(this.localeLang == "en"){
                result = en;
            }
            else if(this.localeLang == "de"){
                result = de;
            }
            
            path.split(".").forEach(function(key:any){
                result = result[key];
              });
            return result;
        },
        /**
         * Sends notifications through email and rocketchat.
         * @param messageType The type of notification the message is (assigned, printed).
         * @param client The client, who created the ticket.
         * @param assignee The assignee, who usually changed the ticket status.
         * @param ticket_name The ticket name, which the status has changed.
         */
        sendNotification(messageType:string, client:string, assignee:string, ticket_name:string, twoWayMessage: boolean){
            const params = {
                client: client,
                assignee: assignee,
                ticket_name: ticket_name,
                twoWayMessage: twoWayMessage,
                email_id: client+"@wzl.rwth-aachen.de",
            }

            if(process.env.VUE_APP_EMAIL_TRAFFIC == "true") this.sendEmail(messageType, params);
            if(process.env.VUE_APP_CHAT_TRAFFIC == "true") this.sendChatMessage(messageType, params);
        },
        sendEmail(messageType:string, params:any){
            //send to client
            emailjs
            .send('service_c1w64tj', this.emailTemplates[messageType], params, {
                publicKey: 'BnH8DBgGXNXplTLdT',
            })
            .then(
                () => {
                    console.log(`SUCCESS! Email was send to ${params.to_name}`);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );
            //send to assignee if client and assignee are not the same user
            if(params.client != params.assignee){
                params.client = params.assignee;
                emailjs
                .send('service_c1w64tj', this.emailTemplates[messageType], params, {
                    publicKey: 'BnH8DBgGXNXplTLdT',
                })
                .then(
                    () => {
                        console.log(`SUCCESS! Email was send to ${params.to_name}`);
                    },
                    (error) => {
                        console.log('FAILED...', error.text);
                    },
                );
            }
        },
        sendChatMessage(messageType:string, params:any){
            let message = "no valid message";

            if(messageType == "assigned"){
                message = `The Ticket *"${params.ticket_name}"* has been assigned to @${params.assignee}`;
            }
            else if(messageType == "printed"){
                message = `@${params.assignee} has *printed* the Ticket *"${params.ticket_name}"*. It is now ready for pick up`;
            }
            else if(messageType == "notify_unassigned_ticket"){
                message = `@${params.assignee} has uploaded the ticket *"${params.ticket_name}"*. Please assign the Ticket to a user with role printer to proceed printing. [Go to Ticket](https://printing.wmaut.de)`;
            }
            

            axios.post(`${process.env.VUE_APP_API_URL}/database/rocketchat/message`, {
                message: message,
                username: params.client
            })
            if(params.twoWayMessage && params.client != params.assignee){
                axios.post(`${process.env.VUE_APP_API_URL}/database/rocketchat/message`, {
                    message: message,
                    username: params.assignee
                })
            }
        },
        /**
         * notifies all printers, that a ticket is unassigned
         * @param client The client, who uploaded the ticket
         * @param ticket_name Ticket name
         */
        notifyAllPrintersOfNewTicket(client: string, ticket_name: string){
            for(const printer of useAuthStore().printerUsers){
                this.sendNotification("notify_unassigned_ticket", printer, client, ticket_name, false);
            }
        },
        /**
         * Calculates date string with the input weeks.
         * @param week number of weeks
         * @returns the date string.
         */
        calcWeekFromNow(week:number){
            const date = new Date( Date.now() + (6.048e+8 * week) )
            return date.toISOString();
        },
        /**
         * Loads the vue component of the specified dialog and shows it.
         * @param dialogName The dialogname from the dialoglist.
         * @param componentParameter A parameter which can be passed to the component (e.q ticket/printer object)
         */
        loadDialog(dialogName: string, componentParameter: any){
            let selectedDialog = {} as any;
            for (const dialog of this.dialogs) {
              if(dialog.name == dialogName){
                selectedDialog = dialog;
                break;
              }
            }
            this.currentDialog.component = () => import(`@/components/dialogs/${selectedDialog.name}.vue`);
            this.currentDialog.id = selectedDialog.name;
            this.currentDialog.title = this.lang(`dialogs.${selectedDialog.name}.title`);
            this.currentDialog.size = selectedDialog.size;
            this.currentDialog.componentParameter = componentParameter;
      
            //show after loading the component the dialog with bootstrap.
            setTimeout(() => {
                // @ts-ignore
                const dialog = new bootstrap.Modal(document.getElementById(selectedDialog.name));
                dialog.show();
            },);
          }
    },
});