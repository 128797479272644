import { defineStore} from "pinia";
// @ts-ignore
import VueJWTDecode from 'vue-jwt-decode';
import Keycloak from "keycloak-js";
import axios from "axios";

export const useAuthStore = defineStore("authStore", {
    state: () =>{
        return {
            //user info
            given_name: "",
            family_name: "",
            email: "",
            email_verified: "",
            preferred_username: "",

            user_role: "",
            last_login: "",
            userRoles: ["client", "printer"],
            users: [] as any,
            printerUsers: [] as string[],

            keycloak: new Keycloak(
                {
                    url: 'https://keycloak.wmaut.de',
                    realm: 'application',
                    clientId: 'printer-system',
                }
            ),
        };
    },
    actions: {
        /**
         * Decodes the jsonWebToken string and loads in the account information from keyCloak.
         * @param token tokenstring
         */
        decodeJWT(token:string){
            const decoded_token = VueJWTDecode.decode(token);

            this.given_name = decoded_token.given_name;
            this.family_name = decoded_token.family_name;
            this.email = decoded_token.email;
            this.email_verified = decoded_token.email_verified;
            this.preferred_username = decoded_token.preferred_username;
        },
        /**
         * Gets the user role the specified user has from the server.
         * @param user_short
         */
        async fetchUserRole(user_short:string){
            try{
                const responseObject:any = await axios.get(`${process.env.VUE_APP_API_URL}/database/users/${user_short}`);
                this.user_role = responseObject.data.role;
                this.last_login = responseObject.data.last_login;
            }
            catch(e){
                this.user_role = this.userRoles[0];
                this.postUser(this.preferred_username, this.userRoles[0]);
            }
           
        },
        async fetchAllUsers(){
            const responseObject = await axios.get(`${process.env.VUE_APP_API_URL}/database/users/`);
            this.users = responseObject.data.items;
        },
        /**
         * Sets the printerUser variable with the user, which have the role printer.
         */
        fetchAllPrinterUsers(){
            const printers = [];
            for (let i = 0; i < this.users.length; i++) {
                if(this.users[i].user_role == this.userRoles[1]){
                    printers.push(this.users[i].user_short)
                }
            }
            this.printerUsers = printers;
        },
        postUser(user_short:string, user_role:string){
            axios.post(`${process.env.VUE_APP_API_URL}/database/users/`,
                {
                    user_short: user_short,
                    user_role: user_role,
                }
            );
        },
        logout(){
            this.keycloak.logout();
        }
    },

});