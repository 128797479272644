<template>
  <div class="d-flex p-4 flex-wrap viewOffset" width="80%">
    <div v-for="m in sortedModels" :key="m.index">
      <model-card :ticket="m"></model-card>
    </div>
    <h3 v-if="sortedModels.length == 0" class="m-4 fst-italic text-muted">{{ manageStore.lang("modelView.ticketPlaceholder") }}</h3>
  </div>

</template>

<script>
import ModelCard from '@/components/cards/ModelCard.vue';
import { useTicketsStore } from '@/stores/tickets';
import { useManageStore } from '@/stores/manage';
import { computed } from "vue";

export default {
  name: 'ModelView',
  components:{
    ModelCard,
  },
  setup(){
    const ticketStore = useTicketsStore();
    const manageStore = useManageStore();
    const models = ticketStore.models;
    manageStore.showNavbar = true;

    const sortArrayOfObjects = (arr, propertyName, order = 'ascending') => {
      const sortedArr = arr.sort((a, b) => {
        if (a[propertyName] < b[propertyName]) {
          return -1;
        }
        if (a[propertyName] > b[propertyName]) {
          return 1;
        }
        return 0;
      });

      if (order === 'descending') {
        return sortedArr.reverse();
      }

      return sortedArr;
    };

    const sortedModels = computed(() => {
      let result = models.filter((el) => {
        let showObject = false;
        if(manageStore.selectedFilter == manageStore.filterTypes[0]){
          showObject = Number(el.status) != 3;
        }
        else if(manageStore.selectedFilter == manageStore.filterTypes[4]){
          showObject = true;
        }
        else{
          showObject = manageStore.filterTypes[Number(el.status)] == manageStore.selectedFilter;
        }
        return showObject;
      });

      result = sortArrayOfObjects(result, "priority", "descending");

      return result;
    })

    return{
      sortedModels,
      manageStore
    }
  }
}

</script>

<style scoped>

.viewOffset{
  margin-top: 130px;
}

</style>